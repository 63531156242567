export const sanitizeFilename = (filename: string): string => {
    // Define a comprehensive set of invalid characters for filenames across different OS
    const invalidChars = /[\/:*?"<>|\\\x00-\x1F\x7F]/g;
    const reservedNames = /^(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$/i;
    const maxLength = 255;

    // Replace invalid characters with an underscore
    let sanitized = filename.replace(invalidChars, '_');

    // Handle reserved names by prefixing and suffixing with an underscore
    if (reservedNames.test(sanitized)) {
        sanitized = `_${sanitized}_`;
    }

    // Truncate the filename if it exceeds the maximum length
    if (sanitized.length > maxLength) {
        sanitized = sanitized.substring(0, maxLength);
    }

    return sanitized;
};

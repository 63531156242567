import { AjaxData, AjaxResponse, InternalSettings } from 'datatables.net-dt';
import { BaseService } from './BaseService';

// Define an interface for AdditionalParameters
type AdditionalParameters = Record<string, any>;

// Extend AjaxData to include AdditionalParameters
interface ExtendedAjaxData extends AjaxData {
    AdditionalParameters: AdditionalParameters;
}

export class DataTableService extends BaseService {
    public async getTableData(
        data: AjaxData,
        datasetReferenceId: string,
        callback: (data: any) => void,
        _settings: InternalSettings,
    ): Promise<void> {
        if (process.env.NODE_ENV === 'development') {
            console.log('Getting table data for dataset', datasetReferenceId, data);
        }

        // Add datasetReferenceId to the AdditionalParameters
        const dataWithAdditionalParams: ExtendedAjaxData = {
            ...data,
            AdditionalParameters: {
                datasetReferenceId: datasetReferenceId,
            },
        };

        const result = await this.getResponseAsync2<AjaxResponse>({
            commandPath: 'datatable',
            method: 'POST',
            body: dataWithAdditionalParams,
        });

        if (process.env.NODE_ENV === 'development') {
            console.log('Dataset Result', result);
        }

        callback(result);
    }
}

import { Link } from '@fluentui/react-components';
import React, { AnchorHTMLAttributes, FC, useCallback, useMemo, useRef } from 'react';
import { IChatMessage } from '../../libs/models/ChatMessage';
import { DataTableService } from '../../libs/services/DataTableService';
import { useOidc } from '../views/OidcProvider';
import AiaDataTable from './AiaDataTable';

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href?: string;
    children?: React.ReactNode;
    message: IChatMessage;
}

const getAttachment = (children: React.ReactNode, message: IChatMessage) => {
    if (typeof children === 'string' && children.startsWith('attachment')) {
        const attachmentIndex = parseInt(children.split(':')[1], 10);
        return message.attachments?.[attachmentIndex];
    }
    return null;
};

const CustomLink: FC<CustomLinkProps> = React.memo(
    ({ href, children, message, ...props }) => {
        const { userManager } = useOidc();
        const dataTablesServiceRef = useRef<DataTableService | null>(null);

        if (!dataTablesServiceRef.current) {
            dataTablesServiceRef.current = new DataTableService(userManager);
        }

        const attachment = useMemo(() => getAttachment(children, message), [children, message]);

        const columnsToShow = useMemo(() => {
            return attachment ? attachment.properties.columnsToShow.split(',') : [];
        }, [attachment]);

        const renderDataTable = useCallback(() => {
            if (columnsToShow.length > 0) {
                return <AiaDataTable message={message} attachment={attachment} />;
            }
            return (
                <Link href={href} {...props}>
                    {children}
                </Link>
            );
        }, [columnsToShow.length, href, props, children, message, attachment]);

        return renderDataTable();
    },
    (prevProps, nextProps) => {
        // Custom comparison function for React.memo
        return (
            prevProps.href === nextProps.href &&
            prevProps.children === nextProps.children &&
            prevProps.message === nextProps.message &&
            prevProps.message.attachments === nextProps.message.attachments
        );
    },
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;

import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { FluentProvider } from '@fluentui/react-components';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { Constants } from './Constants';
import MissingEnvVariablesError from './components/views/MissingEnvVariablesError';
import './index.css';
import { AuthHelper, AuthType } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';

import { UserManager } from 'oidc-client-ts';
import React from 'react';
import { getMissingEnvVariables } from './checkEnv';
import { OidcProvider } from './components/views/OidcProvider';
import { MsalAuthHelper } from './libs/auth/MsalAuthHelper';
import { OidcAuthHelper } from './libs/auth/OidcAuthHelper';
import { semanticKernelLightTheme } from './styles';
import { HostContext } from './embed-script/HostContext';
import hasOwn from 'hasown';
import * as tracking from './libs/tracking';
import { FeatureFlagInitializer } from './components/FeatureFlagInitializer';

if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

// Polyfill for Object.hasOwn
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Object.hasOwn) {
    Object.hasOwn = function (obj, key) {
        return hasOwn(obj, key);
    };
}

tracking.init();

let container: HTMLElement | null = null;
document.addEventListener('DOMContentLoaded', () => {
    (async () => {
        if (!container) {
            container = document.getElementById('root');
            if (!container) {
                throw new Error('Could not find root element');
            }
            const root = ReactDOM.createRoot(container);

            const missingEnvVariables = getMissingEnvVariables();
            const validEnvFile = missingEnvVariables.length === 0;
            const authType: AuthType = AuthHelper.getAuthType();
            const shouldUseMsal = validEnvFile && authType === AuthType.AAD;
            const shouldUseOidc = validEnvFile && authType === AuthType.OIDC;
            const shouldUseNone = validEnvFile && authType === AuthType.None;

            const handleCallbackError = (error: unknown) => {
                console.error('Sign-in error:', error);
            };

            let msalInstance: IPublicClientApplication | null = null;
            if (shouldUseMsal) {
                msalInstance = new PublicClientApplication(MsalAuthHelper.msalConfig);

                void msalInstance.handleRedirectPromise().then((response) => {
                    if (response) {
                        msalInstance?.setActiveAccount(response.account);
                    }
                });
            }
            let hostContext: HostContext | undefined = undefined;
            let userManager: UserManager | null = null;
            if (shouldUseOidc) {
                userManager = new UserManager(OidcAuthHelper.voidcSettings);
                let url = new URL(window.location.href);

                const urlParams = new URLSearchParams(window.location.search);
                if (window.location.pathname === '/' && urlParams.get('code')) {
                    try {
                        const user = await userManager.signinRedirectCallback();
                        if (typeof user.state === 'string') {
                            try {
                                hostContext = JSON.parse(atob(user.state)) as HostContext;
                            } catch (error) {
                                console.error('Failed to parse user state', error);
                            }
                        }
                    } catch {}
                    url = removeOidcParameters(url);
                    window.history.replaceState({}, document.title, url.toString());
                } else {
                    const loggedout = url.searchParams.get('loggedout');
                    const hostContextJson = url.searchParams.get('hostContext');
                    if (hostContextJson) {
                        try {
                            hostContext = JSON.parse(hostContextJson) as HostContext;
                        } catch (error) {
                            console.error('Failed to parse hostContextJson', error);
                        }
                    }

                    const lastlogin = localStorage.getItem('oidcAutoLastLogin');
                    let performAutoLogin = true;
                    if (lastlogin) {
                        const lastloginDate = new Date(lastlogin);
                        const now = new Date();
                        const diff = now.getTime() - lastloginDate.getTime();
                        if (diff < 30000) {
                            performAutoLogin = false;
                        }
                    }
                    if (loggedout) {
                        url = removeOidcParameters(url);
                        window.history.replaceState({}, document.title, url.toString());
                    }
                    if (performAutoLogin) {
                        const user = await userManager.getUser();
                        if (!user || user.expired === true) {
                            localStorage.setItem('oidcAutoLastLogin', new Date().toISOString());
                            userManager
                                .signinRedirect({ state: hostContextJson ? btoa(hostContextJson) : undefined })
                                .catch(handleCallbackError);
                        }
                    }
                }
            }

            root.render(
                <React.StrictMode>
                    {validEnvFile ? (
                        <ReduxProvider store={store}>
                            <FeatureFlagInitializer />
                            {/* eslint-disable @typescript-eslint/no-non-null-assertion */}
                            {shouldUseOidc && (
                                <OidcProvider userManager={userManager!}>
                                    <AppWithTheme hostContext={hostContext} />
                                </OidcProvider>
                            )}
                            {shouldUseMsal && (
                                <MsalProvider instance={msalInstance!}>
                                    <AppWithTheme hostContext={hostContext} />
                                </MsalProvider>
                            )}
                            {shouldUseNone && <AppWithTheme />}
                            {/* eslint-enable @typescript-eslint/no-non-null-assertion */}
                        </ReduxProvider>
                    ) : (
                        <MissingEnvVariablesError missingVariables={missingEnvVariables} />
                    )}
                </React.StrictMode>,
            );
        }
    })().catch((e: unknown) => {
        console.error(e);
    });
});

interface AppWithThemeProps {
    hostContext?: HostContext;
}

const AppWithTheme: React.FC<AppWithThemeProps> = ({ hostContext }) => {
    return (
        <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
            <App hostContext={hostContext} />
        </FluentProvider>
    );
};

function removeOidcParameters(url: URL) {
    url.searchParams.delete('code');
    url.searchParams.delete('scope');
    url.searchParams.delete('session_state');
    url.searchParams.delete('iss');
    url.searchParams.delete('state');
    url.searchParams.delete('loggedout');
    url.searchParams.delete('hostContext');
    return url;
}

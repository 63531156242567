import React, { AnchorHTMLAttributes, FC, useCallback, useMemo, useRef } from 'react';
import DataTable from 'datatables.net-react';
import DT, { AjaxData, AjaxDataColumn, InternalSettings } from 'datatables.net-dt';
import 'datatables.net-select-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-select-dt';
import 'datatables.net-buttons/js/buttons.html5';
import { useTranslation } from 'react-i18next';
import jszip from 'jszip';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import '../../../node_modules/datatables.net-dt/css/dataTables.dataTables.css';
import '../../../src/datatable.css';
import { Attachment, IChatMessage } from '../../libs/models/ChatMessage';
import { DataTableService } from '../../libs/services/DataTableService';
import { useOidc } from '../views/OidcProvider';
import { sanitizeFilename } from './sanitizeFilename';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

DataTable.use(DT);
DT.Buttons.jszip(jszip);
DT.Buttons.pdfMake(pdfMake);

interface CustomLinkProps2 extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href?: string;
    children?: React.ReactNode;
    message: IChatMessage;
    attachment: Attachment | null | undefined;
}

const defaultName = 'Aia export';
const AiaDataTable: FC<CustomLinkProps2> = React.memo(
    ({ attachment }) => {
        const { userManager } = useOidc();
        const { t } = useTranslation();
        const dataTablesServiceRef = useRef<DataTableService | null>(null);

        if (!dataTablesServiceRef.current) {
            dataTablesServiceRef.current = new DataTableService(userManager);
        }

        const columnsToShow = useMemo(() => {
            return attachment ? attachment.properties.columnsToShow.split(',') : [];
        }, [attachment]);

        const columns: AjaxDataColumn[] = useMemo(
            () =>
                columnsToShow.map((column) => ({
                    name: column,
                    data: column,
                    orderable: true,
                    searchable: true,
                    search: { value: '', regex: false },
                })),
            [columnsToShow],
        );

        const ajaxCallback = useCallback(
            (data: AjaxData, callback: (data: unknown) => void, settings: InternalSettings): void => {
                const datasetReferenceId = attachment?.properties.datasetReferenceId;
                if (datasetReferenceId && dataTablesServiceRef.current) {
                    dataTablesServiceRef.current
                        .getTableData(data, datasetReferenceId, callback, settings)
                        .then(() => {})
                        .catch(() => {});
                }
            },
            [attachment],
        );

        const dataTableLanguage = useMemo(
            () => ({
                processing: t('datatable.processing'),
                search: t('datatable.search'),
                lengthMenu: t('datatable.lengthMenu'),
                info: t('datatable.info'),
                infoEmpty: t('datatable.infoEmpty'),
                infoFiltered: t('datatable.infoFiltered'),
                infoPostFix: t('datatable.infoPostFix'),
                loadingRecords: t('datatable.loadingRecords'),
                zeroRecords: t('datatable.zeroRecords'),
                emptyTable: t('datatable.emptyTable'),
                paginate: {
                    first: t('datatable.paginate.first'),
                    previous: t('datatable.paginate.previous'),
                    next: t('datatable.paginate.next'),
                    last: t('datatable.paginate.last'),
                },
                aria: {
                    sortAscending: t('datatable.aria.sortAscending'),
                    sortDescending: t('datatable.aria.sortDescending'),
                },
            }),
            [t],
        );

        const renderDataTable = useCallback(() => {
            if (columnsToShow.length > 0) {
                const sanitizedFilename = sanitizeFilename(attachment?.name ?? defaultName);
                return (
                    <DataTable
                        className="display dataTable"
                        ajax={(data: unknown, callback: (data: unknown) => void, settings: unknown): void => {
                            ajaxCallback(data as AjaxData, callback, settings as InternalSettings);
                        }}
                        options={{
                            serverSide: true,
                            columns,
                            language: dataTableLanguage,
                            scrollX: true,
                            autoWidth: true,
                            layout: {
                                top1Start: 'buttons',
                            },
                            select: true,
                            buttons: [
                                'copy',
                                {
                                    extend: 'csvHtml5',
                                    text: 'CSV',
                                    filename: sanitizedFilename,
                                },
                                {
                                    extend: 'excelHtml5',
                                    text: 'Excel',
                                    filename: sanitizedFilename,
                                },
                                {
                                    extend: 'pdfHtml5',
                                    text: 'PDF',
                                    filename: sanitizedFilename,
                                    title: attachment?.name ?? defaultName,
                                },
                            ],
                        }}
                    >
                        <thead>
                            <tr>
                                {columnsToShow.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                    </DataTable>
                );
            }
            return null;
        }, [columnsToShow, attachment, columns, dataTableLanguage, ajaxCallback]);

        return renderDataTable();
    },
    (prevProps, nextProps) => {
        // Custom comparison function for React.memo
        return (
            prevProps.href === nextProps.href &&
            prevProps.children === nextProps.children &&
            prevProps.message === nextProps.message &&
            prevProps.message.attachments === nextProps.message.attachments
        );
    },
);

AiaDataTable.displayName = 'CustomLink';

export default AiaDataTable;

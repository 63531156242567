import * as signalR from '@microsoft/signalr';
import { t } from 'i18next';
import { Constants } from '../../../Constants';
import { getUserManager } from '../../../components/views/OidcProvider';
import { AuthHelper, AuthType } from '../../../libs/auth/AuthHelper';
import { AlertType } from '../../../libs/models/AlertType';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { BackendServiceUrl } from '../../../libs/services/BaseService';
import { StoreMiddlewareAPI } from '../../app/store';
import { addAlert, removeAlertWithId } from '../app/appSlice';
import { updateMessageProperty } from '../conversations/conversationsSlice';

/*
 * This is a module that encapsulates the SignalR connection
 * to the messageRelayHub on the server.
 */

// These have to match the callback names used in the backend
const enum SignalRCallbackMethods {
    ReceiveMessage = 'ReceiveMessage',
    ReceiveMessageUpdate = 'ReceiveMessageUpdate',
    ReceiveUserTypingState = 'ReceiveUserTypingState',
    ReceiveBotResponseStatus = 'ReceiveBotResponseStatus',
    SessionLoggedOut = 'SessionLoggedOut',
}

const tokenFactory = async () => {
    if (AuthHelper.getAuthType() === AuthType.OIDC) {
        const userManager = getUserManager();
        try {
            const currentUser = await userManager?.getUser();
            return currentUser?.access_token ?? '';
        } catch (error) {
            console.error('Failed to get user:', error);
            return '';
        }
    }
    return '';
};

// Set up a SignalR connection to the messageRelayHub on the server
const setupSignalRConnectionToChatHub = () => {
    const connectionHubUrl = new URL('/messageRelayHub', BackendServiceUrl);

    const signalRConnectionOptions = {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        logger: signalR.LogLevel.Warning,
        accessTokenFactory: tokenFactory,
    };

    // Create the connection instance
    // withAutomaticReconnect will automatically try to reconnect and generate a new socket connection if needed
    const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(connectionHubUrl.toString(), signalRConnectionOptions)
        .withAutomaticReconnect()
        .withHubProtocol(new signalR.JsonHubProtocol())
        .configureLogging(signalR.LogLevel.Information)
        .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    hubConnection.serverTimeoutInMilliseconds = 60000;

    return hubConnection;
};

const registerCommonSignalConnectionEvents = (hubConnection: signalR.HubConnection, store: StoreMiddlewareAPI) => {
    // Re-establish the connection if connection dropped
    hubConnection.onclose((error) => {
        if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
            const errorMessage = t('signalR.disconnected');
            store.dispatch(
                addAlert({
                    message: String(errorMessage),
                    type: AlertType.Error,
                    id: Constants.app.CONNECTION_ALERT_ID,
                }),
            );
            console.log(errorMessage, error);
        }
    });

    hubConnection.onreconnecting((error) => {
        if (hubConnection.state === signalR.HubConnectionState.Reconnecting) {
            const errorMessage = t('signalR.reconnecting');
            store.dispatch(
                addAlert({
                    message: String(errorMessage),
                    type: AlertType.Info,
                    id: Constants.app.CONNECTION_ALERT_ID,
                }),
            );
            console.log(errorMessage, error);
        }
    });

    hubConnection.onreconnected((connectionId = '') => {
        if (hubConnection.state === signalR.HubConnectionState.Connected) {
            const selectedId = store.getState().conversations.selectedId;
            if (selectedId) {
                hubConnection
                    .invoke('AddClientToGroupAsync', selectedId)
                    .then(() => {
                        console.log(` Connected with connectionId ${connectionId} and selectedId ${selectedId}`);
                        const message = t('signalR.connected');
                        store.dispatch(
                            addAlert({ message, type: AlertType.Success, id: Constants.app.CONNECTION_ALERT_ID }),
                        );
                        setTimeout(() => {
                            store.dispatch(removeAlertWithId(Constants.app.CONNECTION_ALERT_ID));
                        }, 7500);
                    })
                    .catch((err: unknown) => {
                        console.error('Error adding client to group:', err);
                    });
            }
        }
    });
};

const startSignalRConnection = (hubConnection: signalR.HubConnection, store: StoreMiddlewareAPI) => {
    registerCommonSignalConnectionEvents(hubConnection, store);
    hubConnection
        .start()
        .then(() => {
            console.assert(hubConnection.state === signalR.HubConnectionState.Connected);
            console.log('SignalR connection established');
        })
        .catch((err: unknown) => {
            console.assert(hubConnection.state === signalR.HubConnectionState.Disconnected);
            console.error('SignalR Connection Error: ', err);
            setTimeout(() => {
                startSignalRConnection(hubConnection, store);
            }, 5000);
        });
};

const registerSignalREvents = (hubConnection: signalR.HubConnection, store: StoreMiddlewareAPI) => {
    hubConnection.on(SignalRCallbackMethods.ReceiveMessage, (chatId: string, _: string, message: IChatMessage) => {
        store.dispatch({ type: 'conversations/addMessageToConversationFromServer', payload: { chatId, message } });
    });

    hubConnection.on(SignalRCallbackMethods.ReceiveMessageUpdate, (message: IChatMessage) => {
        const { chatId, id: messageId, content, contentMetadataArray, graphData, attachments, datasets } = message;
        // If tokenUsage is defined, that means full message content has already been streamed and updated from server. No need to update content again.
        store.dispatch({
            type: 'conversations/updateMessageProperty',
            payload: {
                chatId,
                messageIdOrIndex: messageId,
                property: message.tokenUsage ? 'tokenUsage' : 'content',
                value: message.tokenUsage ?? content,
                frontLoad: true,
                contentMetadataArray,
                graphData,
                attachments,
                datasets,
            },
        });
        store.dispatch(
            updateMessageProperty({
                chatId: chatId,
                messageIdOrIndex: message.id as string,
                property: 'followUpAsks',
                value: message.followUpAsks,
                frontLoad: true,
                contentMetadataArray,
                graphData,
                attachments,
                datasets,
            }),
        );
    });

    hubConnection.on(
        SignalRCallbackMethods.ReceiveUserTypingState,
        (chatId: string, userId: string, isTyping: boolean) => {
            store.dispatch({
                type: 'conversations/updateUserIsTypingFromServer',
                payload: { chatId, userId, isTyping },
            });
        },
    );

    hubConnection.on(
        SignalRCallbackMethods.ReceiveBotResponseStatus,
        (chatId: string, status: string, statusMessage: string | undefined) => {
            store.dispatch({
                type: 'conversations/updateBotResponseStatus',
                payload: { chatId, status, statusMessage },
            });
        },
    );

    hubConnection.on(SignalRCallbackMethods.SessionLoggedOut, async (sessionId: string) => {
        if (store.getState().app.activeUserInfo?.sessionId === sessionId) {
            if (AuthHelper.getAuthType() === AuthType.OIDC) {
                const userManager = getUserManager();
                if (userManager !== undefined) {
                    try {
                        await userManager.clearStaleState();
                        await userManager.revokeTokens();
                        await userManager.removeUser();
                        window.location.search = '?loggedout=true';
                    } catch (error) {
                        console.error(error);
                    }
                }
            } else {
                // @todo MsalAuthHelper.logoutAsync(instance);
            }
        }
    });
};

// This is a singleton instance of the SignalR connection
let hubConnection: signalR.HubConnection | undefined = undefined;

// This function will return the singleton instance of the SignalR connection
let initializingHubConnection: Promise<signalR.HubConnection> | null = null;

export const getOrCreateHubConnection = (store: StoreMiddlewareAPI) => {
    if (process.env.NODE_ENV === 'development') {
        console.log('getOrCreateHubConnection', hubConnection !== undefined, initializingHubConnection !== null);
    }
    if (hubConnection !== undefined) {
        return Promise.resolve(hubConnection);
    }

    if (initializingHubConnection !== null) {
        return initializingHubConnection;
    }

    initializingHubConnection = (async () => {
        const userManager = getUserManager();
        const currentUser = await userManager?.getUser();

        const isAuthNotRequired = !currentUser && AuthHelper.getAuthType() == AuthType.None;
        const isTokenValid = currentUser?.access_token !== undefined && currentUser.expired === false;
        console.log('initializingHubConnection');
        if (isAuthNotRequired || isTokenValid) {
            hubConnection = setupSignalRConnectionToChatHub();

            // Start the signalR connection to make sure messages are
            // sent to all clients and received by all clients
            startSignalRConnection(hubConnection, store);
            registerSignalREvents(hubConnection, store);
        } else {
            throw new Error('Unable to create SignalR connection due to invalid token or auth not required');
        }
        return hubConnection;
    })();

    return initializingHubConnection;
};
